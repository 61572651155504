@import "~bootstrap/scss/bootstrap";
@import "nprogress";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*
Generated by twemoji-amazing, available at https://github.com/SebastianAigner/twemoji-amazing
*/

.twa-crescent-moon {
  background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f319.svg");
}

.twa-garlic {
  background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c4.svg");
}

.nav-pills .nav-link:not(.active):hover {
  background-color: #dfe9fc;
}
